const version = 'v1';
export const environment = {
  env: 'test',
  amplify: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_g10ay61zN',
    userPoolWebClientId: '2u60o89dvvhub52b1rbq7uquq',
    oauth: {
      scope: ['phone', 'email', 'profile', 'openid', 'attributes'],
      identityDomain: 'auth.test.heinet.es',
      identityRedirectSignIn: 'https://test.heinet.es/login/auth-callback',
      identityRedirectSignOut: 'https://test.heinet.es/login',
      responseType: 'code',
    },
  },
  urlDomains: {
    baseUrl: 'https://api.test.heinet.es',
    userUrl: '/accounts/' + version + '/users',
    baseUrlImage: 'https://test.heinet.es',
    logoutUrl: '/logout',
    invoicesUrl: '/invoices/' + version + '/invoices',
    invoicesUpdateTime: '/invoices/' + version + '/sync/info',
    openItemsUrl: '/invoices/' + version + '/invoices/listopenitems',
    prenotificationsUrl: '/invoices/' + version + '/invoices/prenotification',
    documentationUrl: '/invoices/' + version + '/invoices/documentation',
    ordersUrl: '/customer-srv',
    casesUrl: '/customer-srv/' + version + '/case',
    updateTime: '/customer-srv/' + version + '/sync/info',
    distributorsUrl: '/accounts/' + version + '/distributors',
    alertsUrl: '/notifications/' + version + '/notifications',
  },
  directus: {
    baseUrl: 'https://cms.test.heinet.es/',
    accessToken: 'psGjHK9pVXoZvBtm1_7T8nt3OOWn04ax',
    assetsUrl: 'https://cms.test.heinet.es/assets/',
  },
  tools: {
    ordersPortal: ' https://heinekensp--uat.sandbox.my.site.com/services/auth/sso/heinet?startURL=%2F',
    nautilusPortal: 'https://distribuidoresd.socios-comerciales.com/sapui5/nautilus/index.html#/home',
    hesanetPortal: 'https://distribuidoresd.socios-comerciales.com',
    clavePortal: 'https://www.portalclave.com/',
    fuerzaBarPortal: 'https://www.fuerzabar.es/pedidos-backoffice',
    appTuBar: 'https://www.tu-bar.es/mercadodistribuidores',
    ordenesComerciales: 'https://oh.itrfvl.com',
  },
  googleTagManager: {
    gtmId: 'GTM-WVDNQM8',
  },
};
